import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { getJobs } from 'application/repositories/jobRepository';
import { IconText } from 'designsystem/components/2-molecules/IconText';
import { useEffect, useState } from 'react';
import CalendarIcon from 'ui/icons/icon-calendar-small.svg';
import MapPinIcon from 'ui/icons/icon-map-pin-small.svg';
import { formatDate } from 'utilities/date';
import { TeaserModuleFeature } from '../TeaserModule';

export const JobTeaserModuleFeature: React.FC<Content.JobTeaserModule> = ({ content, settings }) => {
	const { heading, link, department, traineesOption, maxNumberOfJobs, numberOfCols } = content?.properties ?? {};
	const { noTopMargin } = settings?.properties ?? {};
	const dictionary = useDictionaryContext();

	const [JobCatalogTeaserItems, setJobCatalogTeaserItems] = useState<Content.TeaserItem[]>([]);

	useEffect(() => {
		const get = async () => {
			const jobCatalogData = await getJobs(department, traineesOption);

			const jobCatalogResult = jobCatalogData.jobs?.splice(0, maxNumberOfJobs);
			const teaserItems = jobCatalogResult?.map((item): Content.TeaserItem => {
				const { heading, additionalInfo, link } = item ?? {};
				return {
					content: {
						properties: {
							heading: heading,
							jobInfo: (
								<div>
									<IconText
										iconSize="sm"
										icon={<CalendarIcon />}
										text={`${dictionary.getLabel('Deadline')}: ${formatDate(additionalInfo.date, 'dd-mm-yyyy')}`}
									/>
									<IconText iconSize="sm" icon={<MapPinIcon />} text={additionalInfo.location} />
								</div>
							),
							urlWrapperLink: link.url,
						},
					},
				};
			});
			setJobCatalogTeaserItems(teaserItems);
		};
		get();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const teaserModuleContent: Content.TeaserModule = {
		content: {
			properties: {
				heading,
				link,
				columns: numberOfCols,
				teaserItems: JobCatalogTeaserItems,
				noItemsMessage: dictionary.getServiceInfo('NoJobs'),
			},
		},
		settings: {
			properties: {
				noTopMargin,
			},
		},
	};
	return <TeaserModuleFeature {...teaserModuleContent} />;
};
