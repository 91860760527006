/* eslint-disable @typescript-eslint/no-unused-vars */
import { axiosInstance as axios } from 'helpers/axiosInstance';
import logger from 'utilities/logger';

//CMS is not exposed to the website, therefore we fetch the news via a Next.JS API

export type TraineesOptionType = 'includeTrainees' | 'excludeTrainees' | 'onlyTrainees';

//Possible department shortnames: ALL, ADST, GAELDST, ITTI, MOTORST, SANST, Skatteforvaltning, SKM, SKTST, Spillemyndigheden. TOLDST, UFST, VURDST
export const getJobDataFromApi = async (department: string, traineesOption: TraineesOptionType): Promise<Models.JobApiResponse> => {
	const getApiUrl = (department: string, traineesOption: TraineesOptionType) => {
		if (traineesOption === 'onlyTrainees') {
			return `GetTranieByOrganizationShortName?shortName=${department}`;
		}
		return `GetJobsByOrganizationShortName?shortName=${department}&includeTranees=${traineesOption === 'includeTrainees'}`;
	};
	try {
		const jobListings = await axios({
			// engagment-api is handled in middleware
			url: `/engagement-api/v1/hrmodule/job/${getApiUrl(department, traineesOption)}`,
			method: 'GET',
		});
		return jobListings?.data as Models.JobApiResponse;
	} catch (error) {
		const message = error?.response?.data?.message ?? error;
		logger.error(`Catalog retrieval failed: ${message}`);
		throw message;
	}
};
