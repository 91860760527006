import { getJobDataFromApi, TraineesOptionType } from 'application/connectors/cms/jobConnector';

export const getJobs = async (
	department: string,
	traineesOption: TraineesOptionType,
): Promise<{ jobs: Models.JobListItem[]; locations: string[]; categories: string[] }> => {
	const jobCatalogData = await getJobDataFromApi(department, traineesOption);
	const mappedLocations = [
		...new Set(
			jobCatalogData?.optionalFilterLocations
				?.map((location): string => {
					return location?.name;
				})
				.filter((e) => e)
				.sort(),
		),
	];

	const mappedCategories = [
		...new Set(
			jobCatalogData?.optionalFilterCategories
				?.map((category): string => {
					return category?.name;
				})
				.filter((e) => e)
				.sort(),
		),
	];

	const mappedResults = jobCatalogData?.jobItems?.map((result): Models.JobListItem => {
		return {
			heading: result.name,
			link: {
				url: result.advertisementUrl,
				name: result.name,
				target: '_blank',
			},
			additionalInfo: {
				date: result.applicationDue,
				location: result.workPlace,
			},
			location: result.positionLocation?.name,
			category: result.positionCategory?.name,
		};
	});
	return { jobs: mappedResults, locations: mappedLocations, categories: mappedCategories };
};
