import classNames from 'classnames';
import { Icon, IconSize } from 'components/1-atoms/Media';
import styles from './IconText.module.scss';

interface IconTextProps {
	className?: string;
	icon: React.ReactNode;
	iconSize?: IconSize;
	text: string;
	spacing?: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
}
export const IconText: React.VFC<IconTextProps> = ({ className, icon, iconSize, text, spacing = 'xs' }) => (
	<div className={classNames(className, styles.IconText)}>
		<Icon size={iconSize}>{icon}</Icon>
		<p className={classNames(styles.IconText_string, styles[`IconText_string___spacing-${spacing}`])}>{text}</p>
	</div>
);
